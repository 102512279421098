.bg {
  background-image: url("/images/background.png");
  /* min-height: 40vh; */
  width: 100%;
  background-size: cover;
  background-position: center center;
  height: auto;
  display: fixed;
  border-bottom: 20px solid #f22276;
}

i {
  color: black;
}

i:hover {
  color: #d81b60;
}

.ib {
  display: inline-block;
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

body {
  min-height: 100vh;
}

/** Hover-related CSS **/
.l > h4,
.l > h1 {
  position: relative;
  color: #fafafa;
  text-decoration: none;
}

.l > h4:hover,
.l > h1:hover {
  color: #fafafa;
}

.l > h4:before,
.l > h1:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fafafa;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.l > h4:hover:before,
.l > h1:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.d > h4,
.d > h1 {
  position: relative;
  color: #212121;
  text-decoration: none;
}

.d > h4:hover,
.d > h1:hover {
  color: #212121;
}

.d > h4:before,
.d > h1:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #212121;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.d > h4:hover:before,
.d > h1:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* Resume Button */

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.buttonFancy {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcf3ec;
}
.button {
  --offset: 10px;
  --border-size: 2px;
  display: block;
  position: relative;
  padding: 1.5em 3em;
  appearance: none;
  border: 0;
  background: transparent;
  color: #fafafa;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0;
  box-shadow: inset 0 0 0 var(--border-size) currentcolor;
  transition: background 0.8s ease;
}
.button:hover {
  background: rgba(100, 0, 0, 0.03);
}
.button__horizontal,
.button__vertical {
  position: absolute;
  top: var(--horizontal-offset, 0);
  right: var(--vertical-offset, 0);
  bottom: var(--horizontal-offset, 0);
  left: var(--vertical-offset, 0);
  transition: transform 0.8s ease;
  will-change: transform;
}
.button__horizontal::before,
.button__vertical::before {
  content: "";
  position: absolute;
  border: inherit;
}
.button__horizontal {
  --vertical-offset: calc(var(--offset) * -1);
  border-top: var(--border-size) solid currentcolor;
  border-bottom: var(--border-size) solid currentcolor;
}
.button__horizontal::before {
  top: calc(var(--vertical-offset) - var(--border-size));
  bottom: calc(var(--vertical-offset) - var(--border-size));
  left: calc(var(--vertical-offset) * -1);
  right: calc(var(--vertical-offset) * -1);
}
.button:hover .button__horizontal {
  transform: scaleX(0);
}
.button__vertical {
  --horizontal-offset: calc(var(--offset) * -1);
  border-left: var(--border-size) solid currentcolor;
  border-right: var(--border-size) solid currentcolor;
}
.button__vertical::before {
  top: calc(var(--horizontal-offset) * -1);
  bottom: calc(var(--horizontal-offset) * -1);
  left: calc(var(--horizontal-offset) - var(--border-size));
  right: calc(var(--horizontal-offset) - var(--border-size));
}
.button:hover .button__vertical {
  transform: scaleY(0);
}

.cardWrap {
  position: relative;
  height: 450px;
  width: 340px;
}
.projectImageContainer {
  height: 180px;
  overflow: hidden;
}

@media screen and (max-width: 375px) {
  .cardWrap {
    width: 280px;
    max-width: 100%;
  }
  .projectImageContainer {
    height: auto;
  }
}
.technicalSkills {
  position: absolute;
  height: 140px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 30px;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.cardWrap:hover .technicalSkills {
  visibility: visible;
  opacity: 1;
}

.anchor {
  margin-top: -200px;
  margin-bottom: 200px;
}

#logo {
  width: 60px;
  @media (max-width: 600px) {
    width: 40px;
  }
}
